import { colors, ControlledField, NoOffersEmailInputSchemaType } from 'remote/global';
import { Typography, Button, InputAdornment } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmailOutlined } from '@mui/icons-material';
import { useOffersAdditionalOffersEmailMutation } from '@/api/queries/offers';
import { useParams } from 'react-router-dom';

interface Props {
  onValid: (data: NoOffersEmailInputSchemaType) => void;
  formInstance: UseFormReturn<any>;
}

function DirectRequestForm({ onValid, formInstance }: Props) {
  const { refNumber } = useParams();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formInstance;

  const offersAdditionalOffersEmailMutation = useOffersAdditionalOffersEmailMutation(refNumber);
  const isEmailSent = offersAdditionalOffersEmailMutation.isSuccess;
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Typography variant='body2' fontSize='small' color={colors.gray1}>
        {t('OffersView.unavailable.directRequest.subtitle')}
      </Typography>
      {/* <ControlledField
        control={formInstance.control}
        name='fullName'
        translationKey='name'
        fieldProps={{ InputProps: { autoComplete: 'full-name' } }}
      /> */}
      <ControlledField
        control={formInstance.control}
        name='email'
        disabled={isSubmitting || isEmailSent}
        focusInitial
        fieldProps={{
          InputProps: {
            type: 'email',
            autoComplete: 'email',
            startAdornment: (
              <InputAdornment position='start'>
                <EmailOutlined />
              </InputAdornment>
            ),
          },
        }}
      />
      {/* <ControlledField
        control={formInstance.control}
        name='phone'
        render={(renderProps) => <PhoneInputField {...renderProps} />}
      /> */}
      <Button variant='contained' fullWidth type='submit' sx={{ mt: '1rem' }}>
        {t('OffersView.actions.contactEmail')}
      </Button>
    </form>
  );
}

export default DirectRequestForm;
