import { useState } from 'react';
import { ModalBase, colors, OfferSchemaType, NoOffersEmailInputSchemaType } from 'remote/global';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { Typography, Box, Button } from '@mui/material';

import DirectRequestForm from './forms/DirectRequestForm/DirectRequestForm';

interface Props {
  onValid: (data: NoOffersEmailInputSchemaType) => void;
  formInstance: UseFormReturn<any>;
  offer: OfferSchemaType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  acceptSmaller: () => void;
}

function BusSizeUnavailableModal({
  acceptSmaller,
  offer,
  onValid,
  formInstance,
  open,
  setOpen,
}: Props) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSeatsFound = offer?.maxSeatsFound ?? 0;
  const { t } = useTranslation();

  return (
    <ModalBase
      fullWidth
      title={
        activeStep === 0
          ? (t('OffersView.unavailable.anotherOption.title') as string)
          : (t('OffersView.unavailable.sendDirectRequest') as string)
      }
      open={open}
      onClose={() => setOpen(false)}
      setOpen={setOpen}
    >
      <Box padding='0 24px 24px' gap={2} display='flex' flexDirection='column'>
        {activeStep === 0 && (
          <>
            <Typography variant='body2' fontSize='small' color={colors.gray1}>
              {t('OffersView.unavailable.anotherOption.description', {
                seats: maxSeatsFound,
              })}
            </Typography>
            <Box
              display='flex'
              gap={6}
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box
                gap={2}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                sx={{
                  width: { xs: '100%', sm: '50%' },
                }}
              >
                <Typography
                  variant='body2'
                  fontSize='small'
                  color={colors.primary}
                  fontWeight='bold'
                  textAlign='center'
                >
                  {t('OffersView.unavailable.anotherOption.option1.title', {
                    seats: maxSeatsFound,
                  })}
                </Typography>
                <Typography variant='body2' fontSize='small' color={colors.gray1}>
                  {t('OffersView.unavailable.anotherOption.option1.description', {
                    seats: maxSeatsFound,
                  })}
                </Typography>
                <Button variant='contained' onClick={acceptSmaller}>
                  {t('OffersView.unavailable.anotherOption.option1.button')}
                </Button>
              </Box>
              <Box
                gap={2}
                display='flex'
                flexDirection='column'
                sx={{
                  width: { xs: '100%', sm: '50%' },
                }}
                justifyContent='space-between'
              >
                <Typography
                  variant='body2'
                  fontSize='small'
                  color={colors.primary}
                  fontWeight='bold'
                  textAlign='center'
                >
                  {t('OffersView.unavailable.anotherOption.option2.title')}
                </Typography>
                <Typography variant='body2' fontSize='small' color={colors.gray1}>
                  {t('OffersView.unavailable.anotherOption.option2.description')}
                </Typography>
                <Button variant='outlined' onClick={() => setActiveStep(1)}>
                  {t('OffersView.unavailable.sendDirectRequest')}
                </Button>
              </Box>
            </Box>
            <Button
              onClick={() => setOpen(false)}
              variant='text'
              sx={{ fontWeight: 500, fontSize: 'small' }}
            >
              {t('OffersView.unavailable.alterSearch')}
            </Button>
          </>
        )}
        {activeStep === 1 && <DirectRequestForm formInstance={formInstance} onValid={onValid} />}
      </Box>
    </ModalBase>
  );
}

export default BusSizeUnavailableModal;
