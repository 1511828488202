import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import {
  useOffersAdditionalOffersEmailMutation,
  useOffersByRefNumberQuery,
  usePostOffersMutation,
} from 'api/queries/offers';
import { usePostClientsOffersMutation } from '@/api/queries/clients';
import {
  OfferSchemaType,
  NoOffersEmailInputSchemaType,
  noOffersEmailInputSchema,
  OfferHeaderTitle,
  isB2bRole,
} from 'remote/global';
import { useAuthClientJwtQuery } from '@/api/queries/auth';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import BusSizeUnavailableModal from './BusSizeUnavailableModal';
import ShortNoticeModal from './ShortNoticeModal';
import OffersLoading from './OffersLoading';

export const DEFAULT_REQUEST_INTERVAL_IN_MS = 1000;
export const OFFER_COUNT_DURATION_IN_SECONDS = 120;

function OfferPollInquirySection({ counter }: { counter: number }) {
  const { t } = useTranslation();
  const { refNumber } = useParams();

  // Polling this will result in other queries with the same queryKey to be also updated
  useOffersByRefNumberQuery(refNumber, {
    refetchInterval: () => {
      if (counter > 0) return DEFAULT_REQUEST_INTERVAL_IN_MS;
      return false;
    },
  });

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box
        sx={{
          my: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: '48px', mb: '38px' }}>
          {t('OffersView.pending.title')}
        </Typography>
        <OffersLoading
          isDone={counter === 0}
          text={t('OffersView.pending.progress.searching', {
            seconds: counter,
          }).toString()}
        />
      </Box>
    </Box>
  );
}

function OffersUnavailableForm({
  defaultValues,
  offers,
  prefill,
}: {
  defaultValues?: NoOffersEmailInputSchemaType;
  offers: OfferSchemaType;
  prefill: any;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { refNumber } = useParams();
  const [open, setOpen] = useState(true);

  const formInstance = useForm<NoOffersEmailInputSchemaType>({
    defaultValues: {
      email: '',
      ...defaultValues,
    },
    resolver: zodResolver(noOffersEmailInputSchema),
  });
  const { data: userData } = useAuthClientJwtQuery();
  const role = userData?.['husky.roles'];
  const postOffersMutation = usePostOffersMutation();
  const postClientsOffersMutation = usePostClientsOffersMutation();
  const navigate = useNavigate();

  const offersAdditionalOffersEmailMutation = useOffersAdditionalOffersEmailMutation(refNumber);
  const isEmailSent = offersAdditionalOffersEmailMutation.isSuccess;

  const onValid = async (data: NoOffersEmailInputSchemaType) => {
    try {
      await offersAdditionalOffersEmailMutation.mutateAsync({
        ...data,
        lang: i18n.language,
      });
      setOpen(false);
    } catch (error) {
      // TODO: Handle error
    }
  };

  const acceptSmaller = async () => {
    if (!prefill || !offers.maxSeatsFound) return;

    const newPayload = {
      ...prefill,
      passengerCount: offers.maxSeatsFound,
    };

    try {
      const response = isB2bRole(role ?? '')
        ? await postClientsOffersMutation.mutateAsync(newPayload as any)
        : await postOffersMutation.mutateAsync(newPayload as any);
      navigate(`/offers/${response.refNumber}`, {
        replace: true,
        state: {},
      });
    } catch (error) {
      // TODO: Handle error
    }
  };

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {offers?.maxSeatsFound && (
        <BusSizeUnavailableModal
          formInstance={formInstance}
          offer={offers}
          onValid={onValid}
          open={open}
          setOpen={setOpen}
          acceptSmaller={acceptSmaller}
        />
      )}
      {offers?.underMinNoticeTime && (
        <ShortNoticeModal
          formInstance={formInstance}
          onValid={onValid}
          open={open}
          setOpen={setOpen}
        />
      )}
      {isEmailSent ? (
        <OfferHeaderTitle
          title={t('OffersView.unavailable.completed.title')}
          subtitle={t('OffersView.unavailable.completed.subtitle')}
        />
      ) : (
        <OfferHeaderTitle
          title={t('OffersView.unavailable.title')}
          subtitle={t('OffersView.unavailable.subtitle')}
        />
      )}
    </Box>
  );
}

interface Props {
  isCounterEnabled: boolean;
  isCounterRunning: boolean;
  counter: number;
  prefill: any;
}

function OffersInquiry({ prefill, isCounterEnabled, isCounterRunning, counter }: Props) {
  const { refNumber } = useParams();

  const {
    data: offers,
    isSuccess: isOffersSuccess,
    isLoading: isOffersLoading,
  } = useOffersByRefNumberQuery(refNumber);

  if (!isOffersSuccess) return null;

  const isAnyOfferAvailable = offers.trips.flatMap((trip) => trip.operatorOffers).length > 0;
  const isNoOffersAvailable = !isCounterEnabled && !isAnyOfferAvailable;

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {isCounterRunning && <OfferPollInquirySection counter={counter} />}
      {isNoOffersAvailable && !isOffersLoading && (
        <OffersUnavailableForm
          prefill={prefill}
          offers={offers}
          defaultValues={{
            email: offers.additionalOffersEmail ?? '',
          }}
        />
      )}
    </Box>
  );
}

export default OffersInquiry;
